import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import LocalizationList from "@/views/localizations/LocalizationList.vue";
import LocalizationCreate from "@/views/localizations/LocalizationCreate.vue";
import SiteCreate from "@/views/sites/SiteCreate.vue";
import SiteList from "@/views/sites/SiteList.vue";
import SeasonList from "@/views/seasons/SeasonList.vue";
import SeasonCreate from "@/views/seasons/SeasonCreate.vue";
import EnumerationCreate from "@/views/enumerations/EnumerationCreate.vue";
import EnumerationsView from "@/views/enumerations/EnumerationsView.vue";
import EnumerationListsView from "@/views/enumerations/EnumerationListsView.vue";
import EnumerationListCreate from "@/views/enumerations/EnumerationListCreate.vue";
import SubjectList from "@/views/subject/SubjectList.vue";
import SubjectRoles from "@/views/subject/SubjectRoles.vue";
import EmailList from "@/views/emails/EmailList.vue";
import EmailCreate from "@/views/emails/EmailCreate.vue";
import AddressListList from "@/views/emails/AddressListList.vue";
import AddressListCreate from "@/views/emails/AddressListCreate.vue";
import SiteEnrolment from "../views/SiteEnrolment.vue";
import EnrolmentSuccess from "@/views/EnrolmentSuccess.vue";
import AwardList from "@/views/awards/AwardList.vue";
import AwardCreate from "@/views/awards/AwardCreate.vue";
import SubjectAwards from "@/views/awards/SubjectAwards.vue";
import SubjectNotes from "@/views/notes/SubjectNotes.vue";
import SubjectRecord from "@/views/subject/SubjectRecord.vue";
import contact from "@/views/staticcontent/contact.vue";
import workplans from "@/views/staticcontent/workplans.vue";
import expensemanagement from "@/views/staticcontent/expensemanagement.vue";
import comms from "@/views/staticcontent/comms.vue";
import surveys from "@/views/staticcontent/surveys.vue";
import policies from "@/views/staticcontent/policies.vue";
import VolunteerProfileView from "@/views/profiles/VolunteerProfileView.vue";
import VolunteerSummary from "@/views/volunteers/VolunteerSummary.vue";
import Reports from "@/views/Reports.vue";
import i18n from "@/i18n";
import WorkshopEvaluations from "@/views/evaluations/WorkshopEvaluations.vue";
import HistoricHoursClaim from "@/views/historic/HistoricHoursClaim.vue";
import OrganizationList from "@/views/organizations/OrganizationList.vue";
import OrganizationCreate from "@/views/organizations/OrganizationCreate.vue";
import help from "@/views/staticcontent/help.vue";
import KitList from "@/views/kits/KitList.vue";
import SiteIntakeStatus from "@/views/sites/SiteIntakeStatus.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/siteenrolment",
    name: "SiteEnrolment",
    component: SiteEnrolment
  },
  {
    path: "/localizations",
    name: "LocalizationList",
    component: LocalizationList
  },
  {
    path: "/localization/create",
    name: "LocalizationCreate",
    component: LocalizationCreate
  },
  {
    path: "/localization/update",
    name: "LocalizationUpdate",
    component: LocalizationCreate
  },
  {
    path: "/enumerations",
    name: "EnumerationsView",
    component: EnumerationsView
  },
  {
    path: "/enumerations/create",
    name: "EnumerationCreate",
    component: EnumerationCreate
  },
  {
    path: "/enumerations/lists",
    name: "EnumerationListsView",
    component: EnumerationListsView
  },
  {
    path: "/enumerations/lists/create",
    name: "EnumerationListCreate",
    component: EnumerationListCreate
  },
  {
    path: "/enumerations/lists/update",
    name: "EnumerationListUpdate",
    component: EnumerationListCreate
  },
  {
    path: "/sites",
    name: "Sites",
    component: SiteList
  },
  {
    path: "/sites/create",
    name: "SiteCreate",
    component: SiteCreate
  },
  {
    path: "/sites/update",
    name: "SiteUpdate",
    component: SiteCreate
  },
  {
    path: "/site/:siteId/intake",
    name: "SiteIntakeStatus",
    component: SiteIntakeStatus
  },
  {
    path: "/seasons",
    name: "Seasons",
    component: SeasonList
  },
  {
    path: "/seasons/create",
    name: "SeasonCreate",
    component: SeasonCreate
  },
  {
    path: "/subjects",
    name: "SubjectList",
    component: SubjectList
  },
  {
    path: "/subject/roles",
    name: "SubjectRoles",
    component: SubjectRoles
  },
  {
    path: "/subject/awards",
    name: "SubjectAwards",
    component: SubjectAwards
  },
  {
    path: "/subject/notes",
    name: "SubjectNotes",
    component: SubjectNotes
  },
  {
    path: "/emails",
    name: "EmailList",
    component: EmailList
  },
  {
    path: "/email/create",
    name: "EmailCreate",
    component: EmailCreate
  },
  {
    path: "/email/update",
    name: "EmailUpdate",
    component: EmailCreate
  },
  {
    path: "/email/addresslists",
    name: "AddressListList",
    component: AddressListList
  },
  {
    path: "/email/addresslist/create",
    name: "AddressListCreate",
    component: AddressListCreate
  },
  {
    path: "/email/addresslist/update",
    name: "AddressListUpdate",
    component: AddressListCreate
  },
  {
    path: "/enrolmentsuccess",
    name: "EnrolmentSuccess",
    component: EnrolmentSuccess
  },
  {
    path: "/awards",
    name: "AwardList",
    component: AwardList
  },
  {
    path: "/award/create",
    name: "AwardCreate",
    component: AwardCreate
  },
  {
    path: "/award/update",
    name: "AwardUpdate",
    component: AwardCreate
  },
  {
    path: "/subject/record",
    name: "SubjectRecord",
    component: SubjectRecord
  },
  {
    path: "/static/contact",
    name: "contact",
    component: contact
  },
  {
    path: "/static/workplans",
    name: "workplans",
    component: workplans
  },
  {
    path: "/static/expensemanagement",
    name: "Expense Management",
    component: expensemanagement
  },
  {
    path: "/static/comms",
    name: "comms",
    component: comms
  },
  {
    path: "/static/surveys",
    name: "surveys",
    component: surveys
  },
  {
    path: "/static/policies",
    name: "policies",
    component: policies
  },
  {
    path: "/volunteer/profile",
    name: "VolunteerProfileView",
    component: VolunteerProfileView
  },
  {
    path: "/volunteer/summary",
    name: "VolunteerSummary",
    component: VolunteerSummary
  },
  {
    path: "/Kits",
    name: "KitList",
    component: KitList
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/:site/workshop/:workshopId/evaluations",
    name: "WorkshopEvaluations",
    component: WorkshopEvaluations
  },
  {
    path: "/HourClaiming",
    name: "HistoricHoursClaim",
    component: HistoricHoursClaim
  },
  {
    path: "/organizations",
    name: "OrganizationList",
    component: OrganizationList
  },
  {
    path: "/organization/create",
    name: "OrganizationCreate",
    component: OrganizationCreate
  },
  {
    path: "/organization/update",
    name: "OrganizationUpdate",
    component: OrganizationCreate
  },
  {
    path: "/static/help",
    name: "help",
    component: help
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    let headers: HTMLCollectionOf<HTMLElement> = document.getElementsByTagName(
      "h1"
    );
    if (headers && headers.length > 0) {
      document.title = headers[0].textContent || "";
    } else {
      //fallback if no h1
      let title = to.name
        ? i18n.t(to.name || "").toString()
        : to.path.substr(1, to.path.length - 1);
      document.title = title;
    }
  });
});

export default router;
